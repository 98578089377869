import React from 'react'
import get from 'lodash/get'
import { Link, navigate } from 'gatsby'
import Img from 'gatsby-image'
import {
  InstantSearch,
  connectStateResults,
  Index,
  SearchBox,
  Hits,
  Configure
} from 'react-instantsearch-dom'
import SearchIcon from '../../../static/img/icons/search-icon.svg';
import SearchClose from '../../../static/img/icons/search-close.svg';
import { findAncestorByClassNameInEventPath, triggerEvent } from "../../helpers"
import algoliasearch from 'algoliasearch/lite'
import InlineHit from './hits/inline'
import HitsList from './hits/list'
// import styles from './search.scss'
// import 'instantsearch.css/themes/algolia.css';

import { ALGOLIA_INDICES, ALGOLIA_GA_EVENTNAME, ALGOLIA_DELAY, ALGOLIA_GA_PROPNAME } from "../../constants"

class Search extends React.Component {
  constructor(props) {
    super(props);    
    this.state = {
      focus: false,
      height: 0,
      query: '',
      searchOpen: false,
    }
    this.setQuery = this.setQuery.bind(this)
    this.setFocus = this.setFocus.bind(this)
    this.toggleSearch = this.toggleSearch.bind(this)
    this.handleDocumentClick = this.handleDocumentClick.bind(this)
    this.triggerSearchAnalyticEvent = this.triggerSearchAnalyticEvent.bind(this)
  }
  
  componentDidMount() {  
    document.addEventListener('click', this.handleDocumentClick);
  }
  
  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  toggleSearch(e){
    const search_state = this.state.searchOpen;
    this.setState( { searchOpen: !search_state } );
    const backTop = document.getElementsByClassName('back-to-top-container');
    if (!search_state) {
      backTop[0].style.display = 'none';
    } else {
      backTop[0].style.display = 'block';
    }
  }

  /**
   * Handles click event so we can close the cart when it a click is made outside it
   * @param  {event} e
   * @return {void}
   */
  handleDocumentClick(e) {
    // These are all the classes that will prevent this from happening
    if (!findAncestorByClassNameInEventPath(e, ['search-results ', 'ais-SearchBox']) && this.state.focus) {
      this.setFocus(false)
    }
  }
  
  /**
   * Sets state query
   * @param {Object} query
   */
  setQuery( { query } ) {
    this.setState( {
      query
    } )
    this.triggerSearchAnalyticEvent(query)
  }
  
  /**
   * Triggers Analytics Event
   * @param  {string} query
   * @return {void}
   */
  triggerSearchAnalyticEvent(query) {
    if (this.searchAnalyticTimeout) {
      clearTimeout(this.searchAnalyticTimeout)
    }
    this.searchAnalyticTimeout = setTimeout(() => {
      if (query) {
        triggerEvent(ALGOLIA_GA_EVENTNAME, {
          [ALGOLIA_GA_PROPNAME]: query
        } )
      }
    }, ALGOLIA_DELAY)
  }
  
  /**
   * Sets state focus and calculates max height for results container
   * @param {boolean} query
   */
  setFocus(focus = true) {
    const maxHeightStyle = {}
    let state = { focus }
    const header = document.querySelector('header')
    const { innerHeight } = window
    const style = header.currentStyle || window.getComputedStyle(header);
    let offsetHeight = header.offsetHeight + (parseInt(style.marginBottom) || 0) + (parseInt(style.marginTop) || 0)
    if (innerHeight && offsetHeight) {
      const maxHeight = innerHeight - offsetHeight - 50 // 50 is a random number just to make sure it doesn't break on some devices
      state.height = maxHeight
    }
    this.setState(state)
  }
  
  render() {    
    const algoliaClient = algoliasearch(
      process.env.GATSBY_ALGOLIA_APP_ID,
      process.env.GATSBY_ALGOLIA_SEARCH_KEY, {
        _useRequestCache: true,
      }
    )
    
    // This will prevent search when query is empty
    // https://www.algolia.com/doc/guides/building-search-ui/going-further/conditional-requests/js/
    const searchClient = {
      search(requests) {
        if (requests.every(( { params } ) => !params.query)) {
          return Promise.resolve( {
            results: requests.map(() => ( {
              hits: [],
              nbHits: 0,
              nbPages: 0,
              processingTimeMS: 0,
              line1:'No Search Results.',
              line2:'Try searching for a product or post.'
            } )),
            
          } );
        }

        return algoliaClient.search(requests);
      }
    }

 

      const StateResults = ( { searchResults } ) => {
        const hasResults = searchResults && searchResults.nbHits !== 0;
        const nbHits = searchResults && searchResults.nbHits;

        return (
          <div className="no-results-wrapper">
            <div className={hasResults ? 'd-none no-results' : 'no-results'}>
              <img src={SearchIcon} alt="No Search Results Icon"/>
              <p>No Search Results.</p>
              <p>Try searching for a product or post.</p>

            </div>
          </div>
        );
      };

      const CustomStateResults = connectStateResults(StateResults);
    

    return (
      <div className="header-search-wrap">
        <div className={this.state.searchOpen ? 'search-toggle search-open': 'search-toggle'} onClick={this.toggleSearch}>
          <img src={SearchIcon} alt="Open Search" title="Click here to start searching"/>
        </div>
        <div className="search-component">
          <div className='search-close' onClick={this.toggleSearch}><img src={SearchClose} alt="Close Search"/></div>
          <InstantSearch 
            indexName="Products" 
            searchClient={searchClient} 
            onSearchStateChange={ this.setQuery }>
            <Configure distinct={1} hitsPerPage={4} typoTolerance={false} />
            <SearchBox showLoadingIndicator 
              onFocus={() => { this.setFocus(true) }}  
              onSubmit={e => {
                e.preventDefault();
                if (typeof window !== 'undefined') {
                  window.location.href = `/search?query=${this.state.query}`;
                }
              }} />
            <div className='search-results'>
              <CustomStateResults/>
              {
                ALGOLIA_INDICES.map(index => (
                  <Index indexName={index} key={index}>
                    <HitsList title={index}/>
                  </Index>
                ))
              }
            </div>
          </InstantSearch>
        </div>
      </div>
    )
  }
}

export default Search
